import 'jquery-ui/ui/widgets/datepicker'

export const datepicker = () => {
  $(".datepicker").datepicker()
  if (getParam("date_from")) {
    $("#date_from").datepicker('setDate', formatDate(getParam("date_from")))
  }
  if (getParam("date_to")) {
    $("#date_to").datepicker('setDate', formatDate(getParam("date_to")))
  }
  $(".datepicker").datepicker("option", "dateFormat", "yy/mm/dd")
}

function getParam(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, " "));
}

function formatDate(date) {
  var dates = date.split('/')
  return dates[1] + '/' + dates[2] + '/' + dates[0]
}
