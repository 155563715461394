import '@hotwired/turbo-rails'
import $ from 'jquery'
import {
  loginButton,
  signOutButton,
  changePasswordButton,
  forgotPasswordButton,
  resetPasswordButton
} from './jquery'
import { datepicker } from './datepicker'

document.addEventListener('turbo:load', () => {
  $(() => {
    loginButton()
    signOutButton()
    changePasswordButton()
    forgotPasswordButton()
    resetPasswordButton()
    datepicker()
  })
})
